// REDUCER FOR COMPANY LIST
export function customerList(state = [], action) {
    switch (action.type) {
    case 'CUSTOMER LIST':
        console.log(action.data);
        return action.data;
    default:
        return state;
    }
}
