import React, { Component } from 'react';
import './uicomponents.css';
import "antd/dist/antd.css";
import {
   Skeleton
} from 'antd';



class CardSkeleton extends Component {

    render() {
        return (
            <div className='conversationskeletondivstart individualcardskeleton'>
               <Skeleton title={false} paragraph={false} active avatar={{shape:'square',size:100}}/>
            </div>
        );
    }
}
export default CardSkeleton;
