
import React, { lazy, Suspense } from "react";
import ReactDOM from 'react-dom';
import './index.css';
import asyncComponent from './Component/AsyncComponent';
import { BrowserRouter, Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "../src/redux/rootReducer";
import AddCreateConversationForm from './Component/AddCreateConversation/AddCreateConversationForm';
import Preloading from './Component/PreloadingPage/Preloading';


const Dashboard = asyncComponent(() => import("./Component/Dashboard/Dashboard"));
const login = asyncComponent(() => import("./Component/LoginForm/LoginForm"));
const forgetpwd = asyncComponent(() => import("./Component/ForgetpwdForm/ForgetpwdForm"));
const Signup = asyncComponent(() => import("./Component/Signup/Signup"));
const NoJobsFound = asyncComponent(() => import("./Component/NojobsFound/NojobsFound"));
const WorkerDashboard = asyncComponent(() => import("./Component/WorkerDashboard/WorkerDashboard"));
// const customer = asyncComponent(() => import("./Component/CustomerDashboard/CustomerDashboard"));
// const HomePage = asyncComponent(() => import("./Component/HomePage/HomePage"));
const HomePage = asyncComponent(() => import("./Component/HomePagev2/HomePagev2"));

// import JobList from './Component/JobList/JobList.js';
// import Dashboard from './Component/Dashboard/Dashboard.js';
// import login from './Component/LoginForm/LoginForm.js';
// import forgetpwd from './Component/ForgetpwdForm/ForgetpwdForm.js';
// import Signup from './Component/Signup/Signup.js';
// import NoJobsFound from './Component/NojobsFound/NojobsFound.js';
// import WorkerDashboard from './Component/WorkerDashboard/WorkerDashboard'
// import customer from './Component/CustomerDashboard/CustomerDashboard.js';
// import HomePage from './Component/HomePage/HomePage';

let store = createStore(rootReducer, applyMiddleware(thunk))
// 

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                HomePage
                  <Route path='/signup' component={Signup} />
                <Route path='/dashboard' render={() => (
                    <Suspense fallback={<Preloading />}>
                        <Dashboard />
                    </Suspense>
                )} />
                <Route path='/workerdashboard' component={WorkerDashboard} />
                <Route path='/NoJobsFound' component={NoJobsFound} />
                {/* <Route path='/customer' component={customer} />
        <Route path='/customer' component={customer} /> */}
        <Route path='/login' component={login} />
        <Route path='/forgetpwd' component={forgetpwd} />
        <Route path='/home' component={HomePage} />
        <Route path='/conversation' component={AddCreateConversationForm} />
        <Route path='/' exact component={HomePage} />
        <Route path='' render={() => (<Redirect to="/home" />)} />



            </Switch>
        </BrowserRouter>
    </Provider>

    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
