// VerifyPage
import React, { Component } from 'react';
import './Preloading.css';
import logo from '../Images/team_clock_logo_v3_01.png';

class Preloading extends Component {

    render() {

        return (
            <div className="homepage_overlay">
                <div id="loader">
                    <div id="box">

                        <img src={logo} className="preloadericon" />
                    </div>
                    <div className="extracls center">
                        <div className="loading">

                            <div className="loading-bar"></div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Preloading;