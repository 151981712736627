// REDUCER FOR Showing footertext globally
export function footertext(state = "", action) {
   
    switch (action.type) {
        case "COPYRIGHT_STATEMENT":
      console.log(action.data)
            return action.data
        default:
            return state
    }
}