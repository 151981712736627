/* eslint-disable indent */
import React from 'react';
import './uicomponents.css';
import 'antd/dist/antd.css';
import { Icon } from 'antd';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

export default function ButtonComponent(props) {
  return (
    <div className={'buttonComponent_start' + ' ' + props.extraclass}>
      <Button
        trigger='click'
        type='primary'
        htmltype='submit'
        className={
          (props.active ? 'buttonactive' : 'buttoninactive') +
          ' ' +
          (props.loading && 'buttonloading') +
          ' ' +
          'mainbuttoncls' +
          ' ' +
          props.buttonextraclass
        }
        onClick={props.buttonClicked}
      >
        {props.loading === true
          ? null
          : props.icon && <Icon type={props.icon} />}
        {props.loading === true
          ? null
          : props.buttonimg && (
              <img src={props.buttonimg} className='buttonimgcls' />
            )}
        {props.loading === true
          ? null
          : props.faicon && <i className={props.faicon}></i>}
        {props.loading === true ? (
          <CircularProgress size={15} className='buttonprogressdesign' />
        ) : (
          props.buttonText
        )}
      </Button>
    </div>
  );
}

ButtonComponent.propTypes = {
  active: PropTypes.bool,
  loading: PropTypes.bool,
  buttonextraclass: PropTypes.string,
  buttonClicked: PropTypes.func,
  icon: PropTypes.any,
  Buttonimg: PropTypes.any,
  buttonText: PropTypes.string
};
