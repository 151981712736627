
/**
 * Reducer for conversationss
 */
export function allConversations(state = [], action) {
    switch (action.type) {
    case 'ALL_CONVERSATIONS_LIST':
        return action.data;
        break;
    default:
        return state;
    }
}