import _groupBy from 'underscore-es/groupBy';

export function workerHours(state = [], action) {
    switch (action.type) {
    case 'WORKERHOURS_LIST':
        // let data = _groupBy(action.data, 'day')
        console.log('WORKERHOURS_LIST',action.data);

        // action.list="yes";
        return action.data;
    default:
        return state
    }
}