/* eslint-disable no-empty */
/* eslint-disable no-console */
import React, { Component } from 'react';
import './uicomponents.css';
import 'antd/dist/antd.css';
import {
    Modal, Icon, Form, Input,
} from 'antd';
import ButtonComponent from './ButtonComponent';

function EmailFieldForm(props) {
    const handleClick = (e) => {
        // console.log(e);
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                // console.log(values.email);
                props.saveEmail(values.email);
            }
        });
    };

    const { getFieldDecorator } = props.form;

    return (
        <div className={'emailfieldformstart'}>
            <Modal
                // title="Vertically centered modal dialog"
                centered
                visible={props.visible}
                wrapClassName="emailfieldmodalcls">
                <div className="alertcloseicon" onClick={props.closeClick}><i class="fas fa-times"></i></div>
                <p className="emailfieldtext">Enter Email Id you are registered with for verification</p>
                <Form>
                    <Form.Item
                        label="Email Address"
                        className="paddingbt"

                    >
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    required: true,
                                    tupe: 'email',
                                    message: 'Please enter your email!'
                                }
                            ]
                        })(<Input
                            type="text" autocomplete="off" />)}

                    </Form.Item>
                    <Form.Item>
                        <ButtonComponent buttonText='Submit' active buttonClicked={handleClick} buttonextraclass="emailsubmitbutton" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default Form.create()(EmailFieldForm);

