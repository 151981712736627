import { combineReducers } from 'redux';
import {userDetail} from './reducers/userDetail';
import {error} from './reducers/error';
import {companylistdata} from './reducers/Companylistdata';
import {customerList} from './reducers/CustomerList';
//import {selectedcompanydata._id} from './reducers/companyid';
import {workerList} from './reducers/workerList';
import {jobListdata} from './reducers/joblistdata';
import { workerJobList } from './reducers/workerJobList';
import {selectedcompanydata} from './reducers/companydata';
import {selectedcompanyuser} from './reducers/selectedCompanyUser';
import {toasts} from './reducers/toast';
import {paymentList} from './reducers/paymentList';
import {footertext} from './reducers/footertext';
import {reportList} from './reducers/Reportlist';
import {creationError} from './reducers/createError';
import {workerReport} from './reducers/workerReport';
import {workerHours} from './reducers/workerHours';
import {removeContinuingJob} from './reducers/removeContinuingJob';
import {managerList} from './reducers/managerList';
import {allConversations} from './reducers/allConversations';
import {customerTags} from './reducers/customerTags';
import {socket} from './reducers/socket'

const rootReducer = combineReducers({
    userDetail,
    error,
    companylistdata,
    customerList,
    //selectedcompanydata._id,
    workerList,
    jobListdata,
    workerJobList,
    selectedcompanydata,
    selectedcompanyuser,
    toasts,
    paymentList,
    footertext,
    reportList,
    creationError,
    workerReport,
    workerHours,
    removeContinuingJob,
    managerList,
    allConversations,
    customerTags,
    socket
});

export default rootReducer;