// REDUCER FOR Worker Job LIST
export function workerJobList(state = {
    dailyUnAssignedJobs:[],dailyAssignedJobs:[],all:[]
}, action) {
    // console.log('Finding jobs');
    switch (action.type) {
    case 'WORKER_JOB_LIST':
        // console.log(action.data,'workerjoblist.');
        return action.data;
    default:
        return state;
    }
}
