// REDUCER FOR COMPANY LIST
export function workerList(state = [], action) {
    switch (action.type) {
        case 'WORKER LIST':
            console.log(action.data); //<-- ???
            return action.data
            break;
        default:
            return state
    }
}