/* eslint-disable no-unreachable */
// REDUCER FOR Manager LIST
export function managerList(state = [], action) {
    switch (action.type) {
    case 'MANAGER LIST':
        return action.data;
        break;
    default:
        return state;
    }
}